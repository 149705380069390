import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from "../../api/Client";
import { notificationActions, NotificationState } from "../slices/notificationSlice";
import { userActions } from "../slices/userSlice";

export const useNotification = () => {
    const dispatch = useDispatch();

    const displayNotification = (notification: NotificationState) => {
        dispatch(notificationActions.addNotification(notification));
    };

    const addNotificationError = (message: string) => {
        dispatch(notificationActions.addNotification({
            type: "error",
            message,
        }));
    };

    const addNotification = (message: string) => {
        dispatch(notificationActions.addNotification({
            message,
        }));
    };

    const addNotificationWarning = (message: string) => {
        dispatch(notificationActions.addNotification({
            type: "warning",
            message,
        }));
    };


    const addNotificationSuccess = (message: string) => {
        dispatch(notificationActions.addNotification({
            type: "success",
            message,
        }));
    };

    const clearNotification = () => {
        dispatch(notificationActions.clearNotification());
    };

    return { addNotification, addNotificationError, addNotificationWarning, addNotificationSuccess, clearNotification } as const;
};

export const useUser = () => {
    const dispatch = useDispatch();
    const { addNotificationError, addNotificationSuccess } = useNotification()
    const navigate = useNavigate();

    const startLoading = () => {
        dispatch(userActions.setUserLoading());
    };

    const endLoading = () => {
        dispatch(userActions.setUserLoaded());
    };

    function loginUser(email: string, password: string) {
        // TODO better update loading
        // dispatch(userActions.setUserLoading());
        startLoading();
        Client_.client_().login(email, password).then(({ err, res }) => {
            dispatch(userActions.setUser(res?.result))
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            }
        });
    }

    function registerUser(username: string, email: string, password: string, firstName: string) {
        startLoading();
        Client_.client_().register(username, email, password, firstName).then(({ err, res }) => {
            endLoading();
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                addNotificationSuccess('Verification email sent. Please check your inbox and spam folder to complete registration.')
                navigate('/login')
            }
        });
    }

    function forgotPassword(email: string) {
        startLoading();
        Client_.client_().forgotPassword(email).then(({ err, res }) => {

            dispatch(userActions.setUser(res?.result))
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            }
        });
    }

    function getUser() {
        Client_.client_().getUser().then(({ err, res }) => {
            dispatch(userActions.setUser(res?.result))
            if (err || !res?.success) {
                addNotificationError(err)
                logoutUser()
                return;
            }
        });
    }

    function logoutUser() {
        Client_.client_().logout().then(({ err, res }) => {
            dispatch(userActions.logoutUser())
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            }
        });
    }

    function loginWithToken(accessToken: string, refreshToken: string) {
        startLoading();
        Client_.client_().loginWithToken(accessToken, refreshToken).then(({ err, res }) => {
            endLoading();
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            }
            dispatch(userActions.setUser(res?.result))
            setTimeout(() => {
                endLoading();
                navigate('/profile');
            }, 100);
        });
    }

    return {
        loginUser,
        registerUser,
        forgotPassword,
        logoutUser,
        getUser,
        loginWithToken
    }
}

const StoreActions = {
    null: null,
}

export default StoreActions;
