import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LensIcon from '@mui/icons-material/Lens';
import { List, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import bbox from '@turf/bbox';
import { useEffect, useRef, useState } from 'react';
import type { HeatmapLayer, MapRef } from 'react-map-gl';
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type CrimeData = {
    'Incident ID': number;
    'Latitude': number;
    'Longitude': number;
    'Incident Description': string;
    'Incident Category': string;
    'Incident Date': string;
    'Incident Time': string;
};

interface ICrimeMapProps {

}

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYWF5c3VzIiwiYSI6ImNsaDQ3aWd3OTFnd24zZHBxNDM3YnV2ZGIifQ.octQiW3i6npkVHwT_acvbA'
const MAPBOX_SESSION_TOKEN = '026a67aa-768a-4bc1-887d-510d1d200a26'

const Legend = () => {
    const gradientStyle = {
        height: '10px',
        width: '100%',
        background:
            'linear-gradient(to right, #440154, #3e4989, #21918c, #5ec962, #fde725)',
        borderRadius: '4px',
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={gradientStyle} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                color: 'white' // Make text visible on dark background
            }}>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Least</Typography>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Middle</Typography>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Most</Typography>
            </div>
        </div>
    );
};

const CrimeMap: React.FC<ICrimeMapProps> = () => {
    const mapRef = useRef<MapRef>(null);
    const [crimeData, setCrimeData] = useState<CrimeData[]>([]);
    const [selectedCrime, setSelectedCrime] = useState<CrimeData | null>(null);
    const [lookbackDays, setLookbackDays] = useState<any>(14);
    const [descriptionSubString, setDescriptionSubString] = useState<string>('Theft, From Locked Vehicle');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchSuggestions, setSearchSuggestions] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [destination, setDestination] = useState<any>(null);
    const [resultSelected, setResultSelected] = useState(false);
    const [coordinates, setCoordinates] = useState<any>(null);
    const [heatmapData, setHeatmapData] = useState<any>(null);
    const [crimeProbability, setSetCrimeProbability] = useState<any>(null);
    const [initialLoading, setInitialLoading] = useState(true);

    const MAX_ZOOM_LEVEL = 16;
    const MAX_LOCATION_ZOOM = 15;
    const heatmapLayer: HeatmapLayer = {
        id: 'heatmap',
        maxzoom: MAX_ZOOM_LEVEL,
        type: 'heatmap',
        paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(0, 0, 0, 0)',
                0.2,
                '#440154',
                0.4,
                '#3e4989',
                0.6,
                '#21918c',
                0.8,
                '#5ec962',
                1,
                '#fde725'
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, MAX_ZOOM_LEVEL, 0]
        },
        source: 'crime-heatmap',
    };



    let navigate = useNavigate();
    const { addNotificationError, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const getCrimeData = () => {
        setIsLoading(true);
        Client_.client_().getCrimeData(parseInt(lookbackDays), descriptionSubString, coordinates?.latitude, coordinates?.longitude).then(({ err, res }) => {
            setIsLoading(false);
            setInitialLoading(false);
            if (err || !res?.success) {
                addNotificationError(err);
                return;
            } else {
                setCrimeData(res?.result?.crime_data);
                setHeatmapData(res?.result?.crime_heatmap);
                setSetCrimeProbability(res?.result?.crime_probability);
            }
        });
    };

    // useEffect(() => {
    //     if (crimeProbability) {
    //         addNotification(`Crime Probability: ${crimeProbability}% at latitude: ${coordinates?.latitude} and longitude: ${coordinates?.longitude}`);
    //     }
    // }, [crimeProbability]);



    useEffect(() => {
        if (searchQuery !== "") {
            const fetchSuggestions = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchQuery}&language=en&access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                setSearchSuggestions(data?.suggestions);
            };
            fetchSuggestions();
        }
    }, [searchQuery]);


    const handleGetLocation = () => {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCoordinates({ latitude, longitude });

                const [minLng, minLat, maxLng, maxLat] = bbox({
                    type: 'Point',
                    coordinates: [longitude, latitude]
                });

                if (mapRef.current) {
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        if (searchResult) {
            setSearchQuery(searchResult.full_address)
            const fetchSearchResult = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/retrieve/${searchResult.mapbox_id}?access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                const feature = data?.features[0];
                // setDestination(feature?.properties);
                setCoordinates(feature?.properties?.coordinates);
                if (feature && mapRef.current) {
                    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
            };
            fetchSearchResult();
            setResultSelected(true);
        }
        console.log(searchSuggestions)
    }, [searchResult]);

    useEffect(() => {
        document.title = 'SF Crime';
        getCrimeData();
    }, [])

    const handleSearchQueryChange = (event: any) => {
        setSearchQuery(event.target.value);
        setResultSelected(false);
        setSetCrimeProbability(null);
    };

    const ControlPanel = () => (
        <Box sx={{ p: 2, width: isMobile ? '100%' : '350px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <h2 style={{ margin: 0 }}>SF Crime Map</h2>
                {isMobile && (
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>

            <TextField
                fullWidth
                size="small"
                label="Lookback Days"
                value={lookbackDays}
                onChange={(e) => setLookbackDays(e.target.value)}
                sx={{ mb: 2 }}
            />

            <TextField
                fullWidth
                size="small"
                label="Crime Type"
                value={descriptionSubString}
                onChange={(e) => setDescriptionSubString(e.target.value)}
                sx={{ mb: 2 }}
            />

            <Box sx={{ position: 'relative', mb: 2 }}>
                <TextField
                    fullWidth
                    size="small"
                    label="Search Location"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
                {searchSuggestions?.length > 0 && !resultSelected && (
                    <Paper sx={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        maxHeight: '200px',
                        overflow: 'auto',
                        mt: 1
                    }}>
                        <List>
                            {searchSuggestions.map((result) => (
                                <ListItemButton
                                    key={result.mapbox_id}
                                    onClick={() => {
                                        setSearchResult(result);
                                        if (isMobile) setDrawerOpen(false);
                                    }}
                                >
                                    <ListItemText
                                        primary={result.name}
                                        secondary={result.full_address}
                                        primaryTypographyProps={{ fontSize: '14px' }}
                                        secondaryTypographyProps={{ fontSize: '12px' }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                )}
            </Box>

            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleGetLocation}
                    startIcon={<GpsFixedIcon />}
                    disabled={isLoading}
                >
                    Find Me
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={getCrimeData}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
            </Box>

            {crimeProbability != null && (
                <Paper sx={{
                    p: 2,
                    bgcolor: 'error.light',
                    color: 'error.contrastText',
                    borderRadius: 2
                }}>
                    <Typography variant="h6">
                        Crime Probability: {crimeProbability}%
                    </Typography>
                </Paper>
            )}

            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
                    Heat Map Legend
                </Typography>
                <Legend />
            </Box>
        </Box>
    );

    return (
        <Box sx={{ height: '100vh', width: '100vw', position: 'relative' }}>
            {isMobile ? (
                <>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 10,
                            left: 10,
                            zIndex: 1,
                            bgcolor: 'background.paper',
                            '&:hover': { bgcolor: 'background.paper' },
                            padding: '8px 16px',
                            boxShadow: 2,
                            borderRadius: '20px',
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                        }}
                        onClick={() => setDrawerOpen(true)}
                        aria-label="Open crime map filters"
                    >
                        <FilterAltIcon />
                        <Typography variant="button" sx={{ fontSize: '14px' }}>
                            Filters
                        </Typography>
                    </IconButton>
                    <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                    >
                        <ControlPanel />
                    </Drawer>
                </>
            ) : (
                <Paper
                    elevation={3}
                    sx={{
                        position: 'absolute',
                        left: 20,
                        top: 20,
                        zIndex: 1,
                        borderRadius: 2,
                        overflow: 'hidden'
                    }}
                >
                    <ControlPanel />
                </Paper>
            )}

            <Map
                initialViewState={{
                    longitude: -122.4474,
                    latitude: 37.7529,
                    zoom: isMobile ? 11 : 12
                }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                style={{ width: '100%', height: '100%' }}
                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                ref={mapRef}
            >
                {crimeData.map(crime => (
                    <Marker
                        key={crime['Incident ID']}
                        latitude={crime['Latitude']}
                        longitude={crime['Longitude']}
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setSelectedCrime(crime)
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <LensIcon style={{ color: 'red', opacity: 0.2, fontSize: 12 }} />
                    </Marker>
                ))}
                {heatmapData && (
                    <Source type="geojson" data={heatmapData}>
                        <Layer {...heatmapLayer} />
                    </Source>
                )}
                {destination && (
                    <Marker
                        key={destination.mapbox_id}
                        latitude={destination.coordinates.latitude}
                        longitude={destination.coordinates.longitude}
                    // anchor='bottom'
                    >
                        <FmdGoodIcon style={{ color: 'green', fontSize: 30 }} />
                    </Marker>
                )}

                {coordinates && (
                    <Marker
                        key={'currentLocation'}
                        latitude={coordinates.latitude}
                        longitude={coordinates.longitude}
                    // anchor='bottom'
                    >
                        <FmdGoodIcon style={{ color: 'green', fontSize: 30 }} />
                    </Marker>
                )}
                {selectedCrime && (
                    <Popup
                        latitude={selectedCrime['Latitude']}
                        longitude={selectedCrime['Longitude']}
                        // anchor='top'
                        onClose={() => setSelectedCrime(null)}
                        style={{ color: 'black' }}
                    >
                        <b>{selectedCrime['Incident Category']}</b> <br />
                        {selectedCrime['Incident Description']} <br />
                        <span style={{ color: 'gray', fontSize: 10 }}>{selectedCrime['Incident Date']} {selectedCrime['Incident Time']} </span>
                    </Popup>
                )}
            </Map>
            {initialLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        textAlign: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        p: 3,
                        borderRadius: 2,
                        maxWidth: '80%'
                    }}
                >
                    <CircularProgress sx={{ mb: 2 }} />
                    <Typography variant="body1" sx={{ color: 'white' }}>
                        Loading crime data for San Francisco...
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'white', mt: 1, opacity: 0.8 }}>
                        First load may take a few minutes. Please be patient. Subsequent updates will be much faster.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};


export default CrimeMap;
