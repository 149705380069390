import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alpha } from '@mui/material/styles';

interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
    const [cartiLoading, setCartiLoading] = useState(false);
    const [apiKeysLoading, setApiKeysLoading] = useState(false);
    const [sessionId, setSessionId] = useState(localStorage.getItem('cartinese_session_id'));
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { getUser, logoutUser } = useUser();


    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification();
    const [cancellingSubscription, setCancellingSubscription] = useState(false);


    useEffect(() => {
        document.title = 'Profile';
        getUser();
    }, []);

    useEffect(() => {
        if (!user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);

    const handleActivateCartinesePremium = () => {
        setCartiLoading(true);
        if (!sessionId) {
            addNotificationError('Could not activate subscription. Please contact support.');
            return;
        }

        Client_.client_().activateCartiSubscription(sessionId).then(({ err, res }) => {
            setCartiLoading(false);
            if (err) {
                addNotificationError(err);
                if (err.toLowerCase().includes('canceled')) {
                    localStorage.removeItem('cartinese_session_id');
                }
            } else {
                getUser();
                localStorage.removeItem('cartinese_session_id');
            }
        });
    };

    const handleCreateApiKey = () => {
        setApiKeysLoading(true);
        Client_.client_().createApiKey().then(({ err, res }) => {
            setApiKeysLoading(false);
            if (err) {
                addNotificationError(err);
            } else {
                getUser();
            }
        });
    };

    const handleCopyApiKey = (apiKey: string) => {
        navigator.clipboard.writeText(apiKey);
        addNotificationSuccess('API key copied to clipboard');
    };


    const handleLogout = () => {
        logoutUser();
        navigate('/');
    };

    const handleCancelSubscription = () => {
        if (window.confirm('Are you sure you want to cancel your subscription? You\'ll continue to have access until the end of your current billing period.')) {
            setCancellingSubscription(true);
            Client_.client_().cancelSubscription().then(({ err, res }) => {
                setCancellingSubscription(false);
                if (err) {
                    addNotificationError(err);
                } else {
                    addNotificationSuccess('Subscription cancelled successfully. You\'ll have access until the end of your billing period.');
                    getUser();
                }
            });
        }
    };

    return (
        <Container maxWidth="sm" sx={{
            mt: { xs: 2, sm: 4 },
            mb: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3 }
        }}>
            <Paper elevation={3} sx={{
                p: { xs: 2, sm: 3 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 1.5, sm: 2 },
                borderRadius: 2,
                background: (theme) => `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.05)}, transparent 20%)`
            }}>
                <Avatar sx={{
                    width: { xs: 80, sm: 100 },
                    height: { xs: 80, sm: 100 },
                    mb: { xs: 1, sm: 2 },
                    fontSize: { xs: '2rem', sm: '2.5rem' },
                    fontWeight: 'bold',
                    background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    border: (theme) => `4px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                    boxShadow: 2
                }}>
                    {user.first_name[0]}
                </Avatar>
                <Typography variant="h4" component="h1" sx={{
                    fontSize: { xs: '1.75rem', sm: '2.125rem' },
                    fontWeight: 600,
                    mb: 0.5
                }}>
                    {user.first_name}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <TextField
                        fullWidth
                        id='username-field'
                        label='Username'
                        disabled
                        variant='outlined'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                                }
                            },
                            '& .MuiInputLabel-root.Mui-disabled': {
                                color: (theme) => alpha(theme.palette.text.primary, 0.5)
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: (theme) => alpha(theme.palette.text.primary, 0.7)
                            }
                        }}
                        value={user.username}
                    />
                    <TextField
                        fullWidth
                        id='email-field'
                        label='Email'
                        disabled
                        variant='outlined'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                                }
                            },
                            '& .MuiInputLabel-root.Mui-disabled': {
                                color: (theme) => alpha(theme.palette.text.primary, 0.5)
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: (theme) => alpha(theme.palette.text.primary, 0.7)
                            }
                        }}
                        value={user.email}
                    />
                </Box>
                <Paper variant="outlined" sx={{
                    p: { xs: 2, sm: 2.5 },
                    width: '100%',
                    borderRadius: 1.5,
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box>
                                <Typography
                                    variant='subtitle1'
                                    sx={{
                                        fontWeight: 600,
                                        lineHeight: 1.2
                                    }}
                                >
                                    Playboi Carti Translator
                                </Typography>
                                {user?.carti_premium?.premium_status && (
                                    <Typography
                                        variant='caption'
                                        color='text.secondary'
                                        sx={{ display: 'block' }}
                                    >
                                        {user?.carti_premium?.subscription_type}
                                        {user?.carti_premium?.premium_expiration &&
                                            ` · Expires ${new Date(user.carti_premium.premium_expiration).toLocaleDateString()}`
                                        }
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 1.5
                    }}>
                        {sessionId && !user?.carti_premium?.premium_status ? (
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={handleActivateCartinesePremium}
                                disabled={cartiLoading}
                                startIcon={cartiLoading ? <CircularProgress size={20} /> : <LockOpenIcon />}
                                sx={{
                                    py: 1,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.9),
                                    '&:hover': {
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                {cartiLoading ? 'Activating...' : 'Activate'}
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={() => navigate('/cartinese')}
                                startIcon={<LaunchIcon />}
                                sx={{
                                    py: 1,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.9),
                                    '&:hover': {
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                {user?.carti_premium?.premium_status ? 'Access Premium' : 'Get Started'}
                            </Button>
                        )}
                        {user?.carti_premium?.premium_status && !user?.carti_premium?.cancelled_at && (
                            <Button
                                fullWidth
                                variant='outlined'
                                color='error'
                                onClick={handleCancelSubscription}
                                disabled={cancellingSubscription}
                                startIcon={cancellingSubscription ?
                                    <CircularProgress size={20} /> :
                                    <CancelIcon />
                                }
                                sx={{
                                    py: 1,
                                    borderColor: (theme) => alpha(theme.palette.error.main, 0.5),
                                    '&:hover': {
                                        borderColor: 'error.main',
                                        bgcolor: (theme) => alpha(theme.palette.error.main, 0.04)
                                    }
                                }}
                            >
                                {cancellingSubscription ? 'Cancelling...' : 'Cancel'}
                            </Button>
                        )}
                    </Box>
                </Paper>
                <Paper variant="outlined" sx={{
                    p: { xs: 2, sm: 2.5 },
                    width: '100%',
                    borderRadius: 1.5,
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                }}>
                    <Accordion
                        elevation={0}
                        sx={{
                            bgcolor: 'transparent',
                            '&:before': { display: 'none' },
                            '& .MuiAccordionSummary-root': {
                                minHeight: { xs: '40px', sm: '48px' },
                                px: { xs: 0, sm: 1 },
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
                                }
                            },
                            '& .MuiAccordionDetails-root': {
                                pt: 0,
                                px: { xs: 0, sm: 1 }
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='api-keys-content'
                            id='api-keys-header'
                        >
                            <Typography variant='h6'>API Keys</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {user?.api_keys && user?.api_keys?.length > 0 ? (
                                <List>
                                    {user.api_keys.map((keyObj: any, index: number) => (
                                        <ListItem key={index} alignItems='flex-start'>
                                            <ListItemText
                                                primary={
                                                    <Typography variant='body1' component='span'>
                                                        {keyObj?.api_key}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography variant='caption' color='text.secondary' component='div'>
                                                            Created {new Date(keyObj?.created_at).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                            <IconButton edge='end' aria-label='copy' onClick={() => handleCopyApiKey(keyObj?.api_key)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography variant='body2' color='text.secondary'>No API keys found</Typography>
                            )}
                            <Button
                                fullWidth
                                variant='outlined'
                                color='primary'
                                onClick={handleCreateApiKey}
                                disabled={apiKeysLoading}
                                startIcon={apiKeysLoading ? <CircularProgress size={20} /> : <VpnKeyIcon />}
                                sx={{ mt: 2 }}
                            >
                                {apiKeysLoading ? 'Creating...' : 'Create New API Key'}
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: 1, sm: 1.5 }
                }}>
                    <Button
                        fullWidth
                        variant='outlined'
                        color='primary'
                        onClick={() => window.location.href = 'mailto:saarimmm@gmail.com'}
                        startIcon={<EmailIcon />}
                        sx={{
                            borderRadius: 1.5,
                            textTransform: 'none',
                            py: 1
                        }}
                    >
                        Contact Support
                    </Button>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={handleLogout}
                        startIcon={<ExitToAppIcon />}
                        sx={{
                            borderRadius: 1.5,
                            textTransform: 'none',
                            py: 1,
                            boxShadow: 2
                        }}
                    >
                        Logout
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default Profile;